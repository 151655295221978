import React from "react";
import { Helmet } from "react-helmet";
import "../../styles/style.scss";
import * as styles from "./plainLayout.module.scss";

const plainLayout = ({ pageName, className, children, title }) => {
  return (
    <>
      <Helmet>
        <link rel="dns-prefetch" href="//fonts.googleapis.com/" />
        <link rel="dns-prefetch" href="//fonts.gtatic.com/" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <script
          defer
          src="https://polyfill.io/v3/polyfill.min.js?features=fetch%2CPromise"
        ></script>
        <script type="application/javascript">
          {`
            {
              (function(d) {
                var config = {
                  kitId: 'bds2sfk',
                  scriptTimeout: 3000,
                  async: true
                },
                h=d.documentElement,t=setTimeout(function(){h.className=h.className.replace(/\bwf-loading\b/g,"")+" wf-inactive";},config.scriptTimeout),tk=d.createElement("script"),f=false,s=d.getElementsByTagName("script")[0],a;h.className+=" wf-loading";tk.src='https://use.typekit.net/'+config.kitId+'.js';tk.async=true;tk.onload=tk.onreadystatechange=function(){a=this.readyState;if(f||a&&a!="complete"&&a!="loaded")return;f=true;clearTimeout(t);try{Typekit.load(config)}catch(e){}};s.parentNode.insertBefore(tk,s)
              })(document);
            }
          `}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-K6QFXMKTP9"></script>
        <script>
          {
            `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
  
            gtag('config', 'G-K6QFXMKTP9');
            `
          }
        </script>
      </Helmet>
      <div className={`LayoutWrapper ${styles.plainLayout}`}>
        <main className={className}>{children}</main>
        <footer className={`${styles.plainLayout__footer}`}>
          <div className={`${styles.plainLayout__copy}`}>
            <small>Copyright © FUKAWA YOUKEI All Rights Reserved.</small>
          </div>
        </footer>
      </div>
    </>
  );
};

export default plainLayout;
