// (1) import層
import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import HeroBtm from "./HeroBtm"
import "./HeroImage.scss"

const HeroImage = () => {
  // const breakpoints = useBreakpoint();
  return (
    <div className="HeroImage">
      <StaticImage
        layout="fullWidth"
        src="../../images/buchiuma/mv_pc-2x.jpg"
        alt="HeroImage"
      />
      <div className="buchiuma_logo">
        <StaticImage
          layout="constrained"
          src="../../images/buchiuma/ttl_logo-2x.png"
          alt="ぶちうま産直市場 山口県の旬な農林水産物を産地直送"
          placeholder="blurred"
          width={640}
          height={145}
        />
      </div>
      <HeroBtm />
    </div>
  )
}
export default HeroImage