import React from "react";
import Seo from "../components/seo";
import { LinkButton } from "../components/index";
import HeroImage from "../components/BuchiumaPage/HeroImage";
import PlainLayout from "../components/Layout/PlainLayout";
import { StaticImage } from "gatsby-plugin-image"; //ローカルから画像を持ってくる
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as styles from "../styles/Buchiumasanchoku.module.scss";
// import { fill } from "lodash";

const Buchiumasanchoku = () => {
  const breakpoints = useBreakpoint();

  return (
    <PlainLayout
      className={`${styles.Buchiumasanchoku}`}
      pageName={`buchiumasanchoku`}
      title={`深川養鶏「らくうるカート」ご案内ページ`}
    >
      <Seo title={`深川養鶏「らくうるカート」ご案内ページ`} />
      <HeroImage />
      <section className={`${styles.Buchiumasanchoku__sec01}`}>
        <h2>
          やまぐちブランド
          <br className={`smBr`} />
          『長州黒かしわ』をお届けします。
        </h2>
        <div className={`${styles.Buchiumasanchoku__btnArea}`}>
          <div>
            <small>＼初めての方はこちら／</small>
            <LinkButton link={`/entry_buchiumasanchoku/`} target="">
              <span className={`${styles.Buchiumasanchoku__btn}`}>
                <strong>会員登録フォーム</strong>
              </span>
            </LinkButton>
          </div>
          <div>
            <small>＼すでに会員の方はこちら／</small>
            <LinkButton link={`https://chosyudori.biz/`} target="_blank">
              <span className={`${styles.Buchiumasanchoku__btn}`}>
                <strong>ぶちうま産直市場</strong>
              </span>
            </LinkButton>
          </div>
        </div>
      </section>
      <section className={`${styles.Buchiumasanchoku__sec02}`}>
        <h2>
          <span>『長州黒かしわ』とは</span>
        </h2>
        <div>
          <p>
            <span className="marker-normal">
              国の天然記念物である『黒柏鶏（くろかしわ）』
            </span>
            の血を受け継ぎ、地域の特色を出し、
            <br className="pcBr" />
            肉用鶏として経済性・食味の良さにこだわり、
            <br className="pcBr" />
            誕生した
            <span className="marker-normal">山口県産のオリジナル地鶏</span>
            です。
            <br />
            長州黒かしわの味・品質は
            <span className="marker-normal">
              抗生物質・合成抗菌剤を使用しない
            </span>
            <br className="pcBr" />
            <span className="marker-normal">ハーブ入りの独自の配合飼料</span>
            に加え、飼料用米や米ぬか等の地域資源を自家配合した飼料を給与し、
            <br className="pcBr" />
            海と山に囲まれた豊かな自然環境のなか、
            <br className="pcBr" />
            <span className="marker-normal">約80～100日間平飼い</span>
            でのびのびと育てることによって高水準な肉質を保っています。
          </p>
          <div>
            {breakpoints.sm ? (
              <StaticImage
                layout="fullWidth"
                fit="fill"
                src="../images/buchiuma/about_sp-2x.png"
                alt=""
                placeholder="blurred"
                width={375}
                height={127}
              />
            ) : (
              <StaticImage
                layout="constrained"
                src="../images/buchiuma/about_pc-2x.png"
                alt=""
                placeholder="blurred"
                width={800}
                height={240}
              />
            )}
          </div>
        </div>
      </section>
      <section className={`${styles.Buchiumasanchoku__sec03}`}>
        <h2>
          <strong>長州黒かしわの<br className="smBr"/>美味しさの特長</strong>
          <small>feature</small>
        </h2>
        <div>
          <div>
          {breakpoints.sm ? (
              <StaticImage
                layout="constrained"
                src="../images/buchiuma/feature_sp.jpg"
                alt=""
                placeholder="blurred"
                width={375}
                height={235}
              />
            ) : (
              <StaticImage
                layout="constrained"
                src="../images/buchiuma/feature_pc.jpg"
                alt=""
                placeholder="blurred"
                width={980}
                height={614}
              />
            )}
          </div>
          <div>
            <p>
            <span className="marker-normal">地鶏の適度な歯ごたえを残しながらも柔らかくジューシーな肉質</span>で、噛めば噛むほど旨味が出てきます。また、鶏肉特有の臭みのない上品な味で、ほんのりと甘い澄んだ脂が特徴です。<br/>
            特にむね肉には、<span className="marker-normal">脳や筋肉の疲労回復を助ける疲労回復機能成分の『イミダペプチド』</span>が多く含まれています。<br/>
            <span className="text-red">※長州黒かしわの胸肉は機能性表示食品です（届出番号:G913）</span><br/>
            料理では、特に<span className="marker-normal">焼き物、鍋物がおススメ</span>です。からだに良く、おいしい長州黒かしわを是非ご賞味ください。
            </p>
          </div>
        </div>
      </section>
      <section className={`${styles.Buchiumasanchoku__sec04}`}>
        <h2>
          <strong>ぶちうま産直市場 <br className="smBr"/>取扱商品</strong>
          <small>products</small>
        </h2>
        <div>
          <div>
          {breakpoints.sm ? (
              <StaticImage
                layout="constrained"
                src="../images/buchiuma/products_sp.jpg"
                alt=""
                placeholder="blurred"
                width={375}
                height={235}
              />
            ) : (
              <StaticImage
                layout="constrained"
                src="../images/buchiuma/products_pc.jpg"
                alt=""
                placeholder="blurred"
                width={980}
                height={614}
              />
            )}
          </div>
          <div>
            <p>
            しっとりとして鶏ハム等に適したむね肉、やわらかくジューシーなもも肉、低カロリーなのに、旨みが凝縮されているささみの３種をご用意しております。
            <br/><br/>
            <small>※左から長州黒かしわむね肉 1kg、長州黒かしわもも肉 1kg、長州黒かしわササミ 1kg</small>
            </p>
          </div>
        </div>
      </section>
      <section className={`${styles.Buchiumasanchoku__sec05}`}>
        <h2>ご注文には、会員登録が必要です。</h2>
        <div className={`${styles.Buchiumasanchoku__btnArea}`}>
          <div>
            <small>＼初めての方はこちら／</small>
            <LinkButton link={`/entry_buchiumasanchoku/`} target="">
              <span className={`${styles.Buchiumasanchoku__btn}`}>
                <strong>会員登録フォーム</strong>
              </span>
            </LinkButton>
          </div>
          <div>
            <small>＼すでに会員の方はこちら／</small>
            <LinkButton
              link={`https://chosyudori.biz/`}
              target="_blank"
              rel="noreferrer"
            >
              <span className={`${styles.Buchiumasanchoku__btn}`}>
                <strong>ぶちうま産直市場</strong>
              </span>
            </LinkButton>
          </div>
        </div>
        <div className={`${styles.Buchiumasanchoku__btnBox}`}>
          {breakpoints.sm ? (
            <small>
              長州黒かしわについて
              <br />
              ＼もっと知りたい方はこちら／
            </small>
          ) : (
            <small>＼長州黒かしわについてもっと知りたい方はこちら／</small>
          )}
          <LinkButton
            link={`//www.chosyu-kurokashiwa.com/`}
            target="_blank"
            rel="noreferrer"
          >
            <span className={`${styles.Buchiumasanchoku__btn}`}>
              <strong>長州黒かしわサイト</strong>
            </span>
          </LinkButton>
        </div>
      </section>
    </PlainLayout>
  );
};
export default Buchiumasanchoku;
