// extracted by mini-css-extract-plugin
export var Buchiumasanchoku = "Buchiumasanchoku-module--Buchiumasanchoku--23QFP";
export var Buchiumasanchoku__sec01 = "Buchiumasanchoku-module--Buchiumasanchoku__sec01--1BAjF";
export var Buchiumasanchoku__sec02 = "Buchiumasanchoku-module--Buchiumasanchoku__sec02--29vrz";
export var Buchiumasanchoku__sec03 = "Buchiumasanchoku-module--Buchiumasanchoku__sec03--2ALr3";
export var Buchiumasanchoku__sec04 = "Buchiumasanchoku-module--Buchiumasanchoku__sec04--3Rahc";
export var Buchiumasanchoku__sec05 = "Buchiumasanchoku-module--Buchiumasanchoku__sec05--Mp9Cd";
export var Buchiumasanchoku__btnArea = "Buchiumasanchoku-module--Buchiumasanchoku__btnArea--1B1FP";
export var Buchiumasanchoku__btnBox = "Buchiumasanchoku-module--Buchiumasanchoku__btnBox--5yKmt";
export var Buchiumasanchoku__btn = "Buchiumasanchoku-module--Buchiumasanchoku__btn--2IRbg";
export var Buchiumasanchoku__wrp = "Buchiumasanchoku-module--Buchiumasanchoku__wrp--3SZTN";